import {
  Button,
  Cascader,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

const AsignClinicianDrawer = (props) => {
  const { isOpen = false, setOpen = () => {}, selectedUsers = [] } = props;
  const [isAssigned, setAssigned] = useState(false);
  const [selectedClinicians, setClinicians] = useState([]);
  const [form] = Form.useForm();

  const onChange = (value, selectedOptions) => {
    setClinicians(selectedOptions);
  };
  const dataSource = Array.from({
    length: 46,
  }).map((_, i) => ({
    value: i,
    label: `Edward King ${i}`,
    name: `Edward King ${i}`,
    age: 32,
    email: `Clinician${i}@gmail.com`,
  }));

  const handleSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: selectedUsers[0]?.name ?? "",
      email: selectedUsers[0]?.email ?? "",
    });
  }, [selectedUsers]);

  return (
    <Drawer
      title={"Add & Assign Clinician"}
      width={500}
      onClose={() => {
        setOpen(false);
      }}
      open={isOpen}
    >
      <Form
        onFinish={handleSubmit}
        // initialValues={{
        //   name: selectedUser[0]?.name ?? "",
        //   email: selectedUser[0]?.email ?? "",
        //   clinician: "",
        // }}
        form={form}
      >
        <Row>
          {!isAssigned ? (
            <>
              <Col span="24">
                <Form.Item
                  layout="vertical"
                  label="SELECTED PATIENTS NAME"
                  name="name"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input readOnly placeholder="Name" size="large" />
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item
                  layout="vertical"
                  label="PATIENTS EMAIL"
                  name="email"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input readOnly placeholder="Email" size="large" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  layout="vertical"
                  label="SELECT A CLINICIANS"
                  name="clinician"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please Select at least one clinician.",
                    },
                  ]}
                >
                  <Cascader
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    className="mb_10"
                    dropdownStyle={{ width: "22%" }}
                    options={dataSource}
                    showSearch={{
                      filter: (inputValue, path) => {
                        const isExist = path[0].name
                          .toLowerCase()
                          .includes(inputValue);
                        return isExist;
                      },
                    }}
                    onChange={onChange}
                    multiple
                    placeholder="Search"
                    suffixIcon={<SearchOutlined height={34} width={24} />}
                    optionRender={(data) => {
                      if (data.value === "__EMPTY__") {
                        return (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              Not Found
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ width: "100%" }}>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {data.name}
                            </p>
                            <p style={{ fontSize: "16px", margin: 0 }}>
                              {data.email}
                            </p>
                          </div>
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col span="24" className="mb_10" style={{ height: "20px" }}>
                <span style={{ fontWeight: "bold", opacity: "60%" }}>
                  SELECTED PATIENTS NAME
                </span>
                <div>{selectedUsers[0]?.name ?? ""}</div>
              </Col>
              <Divider />
              <Col span="24" className="mb_10" style={{ height: "20px" }}>
                <span style={{ fontWeight: "bold", opacity: "60%" }}>
                  PATIENTS EMAIL
                </span>
                <div>{selectedUsers[0]?.email ?? ""}</div>
              </Col>
              <Divider />
              <Col span="24" className="">
                <span style={{ fontWeight: "bold", opacity: "60%" }}>
                  SELECTED CLINICIANS
                </span>
                <div className="mt_5">
                  {selectedClinicians.map((item) => {
                    return (
                      <div>
                        {" "}
                        <p style={{ margin: 0 }}>{item[0]?.name ?? ""}</p>{" "}
                        <p style={{ margin: 0, opacity: "60%" }}>
                          {item[0]?.email ?? ""}
                        </p>{" "}
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Divider />
            </>
          )}

          <Col
            span="24"
            className="text_right mt_10"
            style={{
              bottom: "20px",
              position: "fixed",
              marginTop: "10px",
              right: "10px",
            }}
          >
            {isAssigned && (
              <>
                <Button
                  onClick={() => {
                    setAssigned(false);
                  }}
                  className="mr_10"
                  type="default"
                  size="large"
                  htmlType="submit"
                >
                  BACK
                </Button>
              </>
            )}

            <Button
              onClick={() => {
                setAssigned(true);
              }}
              type="primary"
              size="large"
              htmlType="submit"
            >
              {isAssigned ? "CONFIRM" : "ASSIGN"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AsignClinicianDrawer;
