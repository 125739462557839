import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import TextEditor from "../common/field/TextEditor";

const FaqForm = (props) => {
  const { onViewClose, viewOpen } = props;
  const [addNewUserFormRef] = Form.useForm();

  const isUpdatingUser = false;

  const onDateChange = (data) => {
    console.log("data", data);
  };
  return (
    <Drawer
      title={!isUpdatingUser ? "Add New FAQ" : "Update FAQ"}
      width={500}
      onClose={onViewClose}
      open={viewOpen}
    >
      <Form onFinish={() => {}} form={addNewUserFormRef}>
        <Row>
          {/* <Col span="24">
            <Form.Item
              layout="vertical"
              label="DATE"
              name="date"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              className="mb_10"
              rules={[
                {
                  required: true,
                  message: "Please select date!",
                },
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                onChange={onDateChange}
              />
            </Form.Item>
          </Col> */}
          <Col span="24">
            <Form.Item
              layout="vertical"
              label="TITLE"
              name="title"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              className="mb_10"
              rules={[
                {
                  required: true,
                  message: "Please enter your title!",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span="24" style={{height:"200px"}}>
            <Form.Item
              layout="vertical"
              label="DESCRIPTION"
              name="description"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              className="mb_10"
              rules={[
                {
                  required: true,
                  message: "Please enter description!",
                },
              ]}
            >
              {/* <TextArea style={{width:"100%"}} rows={4} /> */}
              <TextEditor style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* <Col span="24">
            <Form.Item
              layout="vertical"
              label="DESCRIPTION"
              name="description"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              className="mb_10"
              rules={[
                {
                  required: true,
                  message: "Please enter description!",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col> */}

          {/* {isUpdatingUser ? (
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="STATUS"
                name="status"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please select status!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "1",
                      label: "Active",
                    },
                    {
                      value: "0",
                      label: "Deactive",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )} */}
          <Col span="24" className="text_right mt_10">
            <Button type="primary" size="large" htmlType="submit">
              {!isUpdatingUser ? "SAVE" : "UPDATE"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default FaqForm;
