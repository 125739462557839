import { Button, Col, Popconfirm, Row } from "antd";
import React, { useEffect } from "react";
import {
  LeftOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useMainStore } from "../../../store/mainStore";
import { debounce } from "lodash";

const ActivityList = (props) => {
  const { ViewShowDocDrawer, EditShowTypeOneDrawer, confirm, cancel, isOpen } = props;
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;
  const { getMaterial, singleDisorderData, activityMaterial } = disorderStore;

  const getMaterialList = debounce(async () => {
    const [{ _id }] = singleDisorderData;
    console.log("_id in list", _id);
    

    if (!_id) return;

    const newBody = {
      disorderId: _id,
      materialType: "ACTIVITY",
    };

    getMaterial(newBody).then((res) => console.log("res activity", res));
  }, 1000);

  console.log("activityMaterial", activityMaterial);

  useEffect(() => {
    getMaterialList();
  }, [isOpen]);

  // useEffect(() => {
  //   console.log("");
  // }, [])

  return (
    <Row gutter={24}>
      <Col span={24}>
        {activityMaterial?.map((item) => {
          return (
            <div className="materials_inner_card">
              <div className="content">
                <h3 className="title">{item?.activities[0].question ?? ""}</h3>
                <p className="para">{item?.activities[0].activityFile.length ?? ""}</p>
              </div>
              <div className="dashboard_action">
                {/* <Button
                  className="action_btn view mr_5"
                  onClick={ViewShowDocDrawer}
                >
                  <PlayCircleOutlined />
                </Button> */}
                <Button
                  type="primary"
                  className="action_btn mr_5"
                  // onClick={EditShowTypeOneDrawer}
                  onClick={() => EditShowTypeOneDrawer(item)}
                >
                  <EditOutlined />
                </Button>
                {/* <Button className="action_btn delete mr_5">
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Button> */}
              </div>
            </div>
          );
        })}
      </Col>
      {/* <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 2</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeTwoDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 3</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeThreeDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 4</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeFourDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 5</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeFiveDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 6</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeSixDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="materials_inner_card">
          <div className="content">
            <h3 className="title">Day 6</h3>
            <p className="para">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={ViewShowDocDrawer}
            >
              <PlayCircleOutlined />
            </Button>
            <Button
              type="primary"
              className="action_btn mr_5"
              onClick={EditShowTypeSevenDrawer}
            >
              <EditOutlined />
            </Button>
            <Button className="action_btn delete mr_5">
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Button>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default ActivityList;
